<template>
    <div class="order-content">
        <div class="btn-box">
            <el-button type="primary" @click="handleDownload">素材下载</el-button>
        </div>
        <el-table :data="orderList" class="customTable goods-text-table" style="width: 100%; flex: 1; margin-top: 20px"
            height="100%">
            <el-table-column label="产品名称" align="left" width="380px">
                <template slot-scope="scope">
                    <!--                    <router-link class="answer" :to="{path: '/goods/detail', query: {g_id: scope.row.id }}" target="_blank">-->
                    <!--                        <img :src="scope.row.goods_img" alt="">-->
                    <!--                        <span class="text">{{scope.row.goods_title}}</span>-->
                    <!--                    </router-link>-->
                    <div class="answer">
                        <img :src="scope.row.goods_img" alt="">
                        <span class="text">{{ scope.row.goods_title }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="order_no" label="订单编号" align="center"></el-table-column>
            <el-table-column label="单价/数量" align="center">
                <template slot-scope="scope">
                    <p>￥{{ scope.row.unit_price }}</p>
                    <p> x {{ scope.row.number }}</p>
                </template>
            </el-table-column>
            <!-- <el-table-column  label="物流信息" align="center">
                <template slot-scope="scope">
                    <p>{{scope.row.logistics>0?scope.row.logistics:'暂无快递信息'}}</p>

                    <p>{{scope.row.odd_numbers>0?scope.row.odd_numbers:'暂无物流单号'}}</p>
                </template>
            </el-table-column> -->
            <el-table-column prop="freight" label="运费" align="center"></el-table-column>
            <el-table-column prop="pay_amount" label="支付金额" align="center"></el-table-column>
            <!-- <el-table-column prop="order_status" label="订单状态" align="center"></el-table-column> -->
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <span style="margin-right: 10px;color: #ccc; font-size: 14px;">{{
                        scope.row.order_status === '已发货' ?
                        '已发货' : ''
                    }}</span>
                    <el-link type="primary" v-if="scope.row.order_status === '待发货'" :underline="false"
                        @click="orderBtn(scope.row)">发货</el-link>
                    <el-link type="success" v-else-if="scope.row.order_status === '已发货'" :underline="false"
                        @click="lookData(scope.row)">查看</el-link>
                    <el-link type="danger" v-if="scope.row.order_status === '已发货'" :underline="false"
                        @click="orderBtn(scope.row)">修改</el-link>

                    <!--                    <el-link type="primary" v-if="scope.row.order_status==='（锁定）已发货'||scope.row.order_status==='通过（（删除）已发货）'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>-->
                    <!--                    <el-link type="primary" v-if="scope.row.order_status==='（锁定）交易完成'||scope.row.order_status==='通过（（删除）交易完成）'||scope.row.order_status==='通过（（换货）待发货）'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>-->
                    <!--                    <el-link type="primary" v-if="scope.row.scoring_operation==='出库'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>-->
                    <!--                    <el-link type="success" v-if="scope.row.scoring_operation==='修改快递单号'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>-->
                    <!--                    <el-link type="primary" v-if="scope.row.scoring_operation==='退款审核'||scope.row.scoring_operation==='退货审核'||scope.row.scoring_operation==='换货审核'" :underline="false" @click="orderBtn(scope.row)">{{scope.row.is_answer===1?'修改':scope.row.scoring_operation}}</el-link>-->
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗-->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="modifyOrderTitle"
            @close="resetForm()" custom-class="green-dialog02" :visible.sync="modifyOrderDialog" width="35%">
            <el-form :model="modifyOrderForm" ref="modifyOrderForm" label-position="right" label-width="100px">
                <!-- <el-form-item label="订单号:"  prop="order_no">
                    <div class="order-no-title">{{modifyOrderForm.order_no}}</div>
                </el-form-item> -->
                <el-form-item label="请填写发货信息:" prop="order_no" label-width="auto">
                    <el-input type="textarea" placeholder="请填写发货信息" :rows="3" style="width:300px"
                        v-model="modifyOrderForm.delivery_information"></el-input>
                </el-form-item>


                <div class="order-item" v-if="this.orderStatus === '待发货' || this.orderStatus === '已发货'">
                    <el-form-item prop="logistics" label="请选择物流公司" label-width="auto">
                        <el-select v-model="modifyOrderForm.logistics" placeholder="请选择" style="width:180px">
                            <el-option v-for="item in logisticsList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>

                </div>
                <div class="order-item" v-if="this.orderStatus === '待发货' || this.orderStatus === '已发货'">
                    <el-form-item label-width="auto" label="请填写物流单号" prop="odd_numbers">
                        <el-input v-model="modifyOrderForm.odd_numbers" placeholder="请输入物流单号" style="width:256px">
                        </el-input>
                    </el-form-item>
                </div>
                <div v-else>
                    <el-form-item prop="delivery_status" label="出库情况" v-if="this.orderStatus === '（锁定）待发货' || this.orderStatus === '通过（（删除）待发货）'
                    || this.orderStatus === '（锁定）已发货' || this.orderStatus === '通过（（删除）已发货）'">
                        <el-select v-model="modifyOrderForm.delivery_status" clearable placeholder="请选择出库情况"
                            style="width:360px" @change="deliveryStatus">
                            <el-option v-for="item in deliveryStatusList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="audit_reasons" label="审核原因" v-else>
                        <el-select v-model="modifyOrderForm.audit_reasons" clearable placeholder="请选择审核原因"
                            style="width:360px" @change="auditReasons">
                            <el-option v-for="item in auditReasonsList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="reasons_rejection" label="驳回原因">
                        <el-select v-model="modifyOrderForm.reasons_rejection" clearable placeholder="请选择驳回原因"
                            style="width:360px" @change="reasonsRejection">
                            <el-option v-for="item in reasonsRejectionList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <p class="notes-title">注：出库情况：审核通过需填写实际出库情况； 驳回原因：驳回时需选择驳回原因</p>
                </div>
            </el-form>
            <div class="dialog-footer">
                <el-button @click="modifyOrderDialog = false">取 消</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn()"
                    v-if="this.orderStatus === '待发货'">出库</el-button>
                <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn()"
                    v-else-if="this.orderStatus === '已发货'">确定</el-button>
                <div v-else>
                    <el-button style="margin-left: 30px" type="primary" @click="addOrderBtn(1)"
                        :disabled="!hasOutBound && !hasAuditReasons">通过</el-button>
                    <el-button style="margin-left: 30px" type="primary" plain @click="addOrderBtn(2)"
                        :disabled="!hasRejection && !hasOutBound && !hasAuditReasons">驳回</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 查看 -->
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="lookTitle" @close="close"
            custom-class="green-dialog02" :visible.sync="lookDialog" width="35%">
            <el-form :model="modifyOrderForm" ref="modifyOrderForm" label-position="right" label-width="100px">
                <el-form-item label="请填写发货信息:" prop="order_no" label-width="auto">
                    <el-input :disabled="orderStatus === '已发货'" type="textarea" placeholder="请填写发货信息" :rows="3"
                        style="width:300px" v-model="modifyOrderForm.delivery_information"></el-input>
                </el-form-item>
                <div class="order-item" v-if="this.orderStatus === '待发货' || this.orderStatus === '已发货'">
                    <el-form-item prop="logistics" label="请选择物流公司" label-width="auto">
                        <el-select :disabled="orderStatus === '已发货'" v-model="modifyOrderForm.logistics"
                            placeholder="请选择" style="width:180px">
                            <el-option v-for="item in logisticsList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>

                </div>
                <div class="order-item" v-if="this.orderStatus === '待发货' || this.orderStatus === '已发货'">
                    <el-form-item label-width="auto" label="请填写物流单号">
                        <el-input :disabled="orderStatus === '已发货'" v-model="modifyOrderForm.odd_numbers"
                            placeholder="请输入物流单号" style="width:256px">
                        </el-input>
                    </el-form-item>
                </div>
                <div v-else>
                    <el-form-item prop="delivery_status" label="出库情况" v-if="this.orderStatus === '（锁定）待发货' || this.orderStatus === '通过（（删除）待发货）'
                    || this.orderStatus === '（锁定）已发货' || this.orderStatus === '通过（（删除）已发货）'">
                        <el-select v-model="modifyOrderForm.delivery_status" clearable placeholder="请选择出库情况"
                            style="width:360px" @change="deliveryStatus">
                            <el-option v-for="item in deliveryStatusList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="audit_reasons" label="审核原因" v-else>
                        <el-select v-model="modifyOrderForm.audit_reasons" clearable placeholder="请选择审核原因"
                            style="width:360px" @change="auditReasons">
                            <el-option v-for="item in auditReasonsList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="reasons_rejection" label="驳回原因">
                        <el-select v-model="modifyOrderForm.reasons_rejection" clearable placeholder="请选择驳回原因"
                            style="width:360px" @change="reasonsRejection">
                            <el-option v-for="item in reasonsRejectionList" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <p class="notes-title">注：出库情况：审核通过需填写实际出库情况； 驳回原因：驳回时需选择驳回原因</p>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import { stuOpOrderList, stuOpOrderOperation, newStuOpOrderOperation, stuOpModifyOrder } from '@/utils/apis.js'
export default {
    name: "OrderManage",
    data() {
        return {
            orderList: [],
            modifyOrderForm: {
                logistics: '',
                order_no: '',
                odd_numbers: '',
                delivery_status: '',
                reasons_rejection: '',
                audit_reasons: '',
                delivery_information: '',
            },
            modifyOrderTitle: '',
            modifyOrderDialog: false,
            deliveryStatusList: [],
            logisticsList: [],
            reasonsRejectionList: [],
            auditReasonsList: [],
            orderId: '',
            orderStatus: '',
            scoringOperation: '',
            hasOutBound: false,//是否有选择出库情况
            hasRejection: false,//是否有选择驳回原因
            hasAuditReasons: false,//是否有选择审核原因
            lookTitle: '',
            lookDialog: false,
        }
    },
    mounted() {
        this.getStuOpOrderList()
        // this.getStuOpOrderOperation()
    },
    methods: {
        handleDownload() {
            if (Number(this.$route.query.exam_module_id) === 18 ||Number(this.$route.query.exam_module_id) === 91 ||Number(this.$route.query.exam_module_id) === 67) {
                let r = window.confirm('确定下载素材么？')
                if (r === true) {
                    window.open(localStorage.getItem('file_download'))
                } else {
                    console.log('已取消')
                }
            }
        },
        close(){
            this.modifyOrderForm.logistics = ''
            this.modifyOrderForm.odd_numbers = ''
            this.modifyOrderForm.delivery_information = ''
        },
        // 获取订单列表
        getStuOpOrderList() {
            let param = {}
            if (this.$route.query.id) {
                param.op_id = this.$route.query.id;
            }
            if (this.$route.query.course_id) {
                param.course_id = this.$route.query.course_id;
            }

            stuOpOrderList(param).then((res) => {
                this.orderList = res.data.list
            }).catch((err) => {
                console.log(err);
            })
        },
        // 出库
        deliveryStatus(e) {
            if (e) {
                this.hasOutBound = true;
            } else {
                this.hasOutBound = false;
            }
        },
        // 驳回
        reasonsRejection(e) {
            if (e) {
                this.hasRejection = true;
            } else {
                this.hasRejection = false;
            }
        },
        // 审核
        auditReasons(e) {
            if (e) {
                this.hasAuditReasons = true;
            } else {
                this.hasAuditReasons = false;
            }
        },
        // 获取订单操作
        getStuOpOrderOperation() {
            let param = {
                id: this.orderId
            }
            if (this.$route.query.id) {
                param.op_id = this.$route.query.id;
            }
            if (this.$route.query.course_id) {
                param.course_id = this.$route.query.course_id;
            }
            if(this.$route.query.exam_module_id == 67 || this.$route.query.exam_module_id == 91){
                newStuOpOrderOperation(param).then((res) => {
                    this.deliveryStatusList = res.data.delivery_status
                    this.logisticsList = res.data.logistics
                    this.reasonsRejectionList = res.data.reasons_rejection
                    this.auditReasonsList = res.data.audit_reasons
                }).catch((err) => {
                    console.log(err);
                })
            } else {
                stuOpOrderOperation(param).then((res) => {
                    this.deliveryStatusList = res.data.delivery_status
                    this.logisticsList = res.data.logistics
                    this.reasonsRejectionList = res.data.reasons_rejection
                    this.auditReasonsList = res.data.audit_reasons
                }).catch((err) => {
                    console.log(err);
                })
            }
        },
        lookData(row) {
            console.log(row)
            this.lookDialog = true;
            this.orderStatus = row.order_status;
            if (this.orderStatus === '已发货') {
                this.lookTitle = '查看快递单号'
            }
            this.modifyOrderForm.logistics = row.logistics
            this.modifyOrderForm.odd_numbers = row.logistics_number
            this.modifyOrderForm.delivery_information = row.delivery_information
        },
        orderBtn(row) {
            console.log(row)
            this.modifyOrderDialog = true;
            console.log('row', row);
            console.log('modify', this.modifyOrderForm);
            this.orderId = row.id
            this.orderStatus = row.order_status
            this.modifyOrderForm.order_no = row.order_no
            this.modifyOrderForm.logistics = row.logistics
            this.modifyOrderForm.delivery_status = row.delivery_status
            this.modifyOrderForm.audit_reasons = row.audit_reasons
            this.modifyOrderForm.reasons_rejection = row.reasons_rejection == '0' ? '' : row.reasons_rejection

            this.getStuOpOrderOperation()
            if (this.orderStatus === '待发货' || this.orderStatus === '已发货') {
                this.modifyOrderTitle = '出库'
            }
            if (this.orderStatus === '已发货') {
                this.modifyOrderTitle = '修改快递单号'
                this.modifyOrderForm.delivery_information = row.delivery_information
                this.modifyOrderForm.odd_numbers = row.logistics_number ? row.logistics_number : "";
            }
            if (this.orderStatus === '（锁定）待发货' || this.orderStatus === '通过（（删除）待发货）'
                || this.orderStatus === '（锁定）已发货' || this.orderStatus === '通过（（删除）已发货）') {
                this.modifyOrderTitle = '退款单审核'
            }
            if (this.orderStatus === '（锁定）交易完成' || this.orderStatus === '通过（（删除）交易完成）') {
                this.modifyOrderTitle = '退货单审核'
            }
            if (this.orderStatus === '（锁定）交易完成' || this.orderStatus === '通过（（换货）待发货）') {
                this.modifyOrderTitle = '换货单审核'
            }
        },
        // 订单确定事件
        addOrderBtn(operation) {
            let param = {
                id: this.orderId
            }
            if (this.$route.query.id) {
                param.op_id = this.$route.query.id;
            }
            if (this.$route.query.course_id) {
                param.course_id = this.$route.query.course_id;
            }
            if (operation === 1) {
                param.operation = 1
            } else if (operation === 2) {
                param.operation = 2
            }
            if (this.orderStatus === '待发货' || this.orderStatus === '已发货') {
                param.logistics = this.modifyOrderForm.logistics
                param.logistics_number = this.modifyOrderForm.odd_numbers
                param.delivery_information = this.modifyOrderForm.delivery_information
            }
            if (this.orderStatus === '（锁定）待发货' || this.orderStatus === '通过（（删除）待发货）'
                || this.orderStatus === '（锁定）已发货' || this.orderStatus === '通过（（删除）已发货）') {
                param.delivery_status = this.modifyOrderForm.delivery_status
                param.reasons_rejection = this.modifyOrderForm.reasons_rejection
            }
            if (this.orderStatus === '（锁定）交易完成' || this.orderStatus === '通过（（删除）交易完成）'
                || this.orderStatus === '（锁定）交易完成' || this.orderStatus === '通过（（换货）待发货）') {
                param.audit_reasons = this.modifyOrderForm.audit_reasons
                param.reasons_rejection = this.modifyOrderForm.reasons_rejection
            }
            if (this.modifyOrderForm.odd_numbers.length < 2 || this.modifyOrderForm.odd_numbers.length > 16) {
                this.$message.warning("物流单号长度为2到16个字符")
                return false
            }else{
               stuOpModifyOrder(param).then((res) => {
                this.$message.success(res.msg);
                this.getStuOpOrderList()
                this.modifyOrderDialog = false;
                this.resetForm()
            }).catch((err) => {
                console.log(err);
            }) 
            }
            // stuOpModifyOrder(param).then((res) => {
            //     this.$message.success(res.msg);
            //     this.getStuOpOrderList()
            //     this.modifyOrderDialog = false;
            //     this.resetForm()
            // }).catch((err) => {
            //     console.log(err);
            // })
        },
        // 重置表单
        resetForm() {
            this.$refs.modifyOrderForm.resetFields();
            this.modifyOrderForm = this.$options.data().modifyOrderForm;
            this.hasOutBound = false;
            this.hasRejection = false;
            this.hasAuditReasons = false;
        },
    }
}
</script>

<style scoped lang="scss">
.order-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .btn-box {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .answer {
        display: flex;
        align-items: center;

        img {
            width: 80px;
            height: 80px;
        }

        .text {
            padding-left: 12px;
        }
    }

    .order-item {
        display: flex;
        align-items: center;

        ::v-deep .el-form-item__content {
            display: flex;
            margin-left: 24px !important;
        }

        ::v-deep .el-input-number {
            .el-input__inner {
                text-align: left;
            }
        }
    }

    .notes-title {
        color: #999;
        padding: 0 100px;
    }

    .dialog-footer {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }
}
</style>